import React from 'react'
import { Heading } from 'theme-ui'
import Divider from '@components/Divider'

/**
 * Shadow me to add your own content
 */

const styles = {
  heading: {
    background: t => `
      linear-gradient(
        45deg,
        ${t.colors.primary} 32.5%,
        ${t.colors.secondary} 50.5%)
    `,
    '-webkit-background-clip': `text`,
    '-webkit-text-fill-color': `transparent`,
    fontWeight: `bold`
  },
  running: {
    color: `grayDark`,
    fontWeight: `normal`,
    maxWidth: [`full`, `full`, `2/3`]
  }
}

export default () => (
  <>
    <Divider space={3} />
    <Heading variant='h1' sx={styles.heading}>
      Encuentra productos y servicios
      <br />
      de las mejores empresas. #CompraLocal
    </Heading>
    <Divider space={3} />
    <Heading variant='h3' sx={styles.running}>
      Pequeñas y medianas empresas en México necesitan de tu ayuda
      para poder seguir creciendo. Apoya a tus familiares, amigos, y
      a tu localidad. Encuentra aquí aquellos negocios locales.
    </Heading>
  </>
)
